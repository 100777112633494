.form-field {
  @apply text-black px-2 py-1;
}

.field-group {
  @apply flex items-center gap-2 justify-between;
}

.puzzle-detail-tab {
  @apply flex-grow text-left border-b-2 py-2 text-lg px-1 font-semibold;
}

.puzzle-detail-tab-selected {
  @apply text-cyan-600 border-cyan-600;
}

.puzzle-detail-tab-deselected {
  @apply border-gray-300;
}
